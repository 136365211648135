import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";
import { Modal } from "bootstrap";

import "../asset/style/home.scss";
import Brand from "../asset/img/ppu-logo-brand.png";
import AvaIcon from "../asset/img/Ava-Icon.svg";
import NoThumbnail from "../asset/img/NoThumbnail.svg";

import Loading from "../components/Loading";

import AuthAPI from "../api/Auth";
import UserAPI from "../api/User";
import ApplicationAPI from "../api/Application";
import ClusterAPI from "../api/Cluster";
import { Lock, LogOut, User } from "react-feather";

class Home extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      isPass: "",

      apps: [],
      publicApps: [],
      populatedCluster: [],

      user: {
        _id: "",
        username: "",
        name: "",
        email: "",
        phone: "",
        nip: "",
        apps: [],
      },
      password: ["", ""],

      modal: {
        password: null,
        announcement: null,
        profile: null,
      },

      modalProfile: {
        name: "",
        email: "",
        phone: "",
        nip: "",
      },
    };

    this.handleToken = this.handleToken.bind(this);
    this.fetchNow = this.fetchNow.bind(this);

    this.handleChange = this.handleChange.bind(this);

    this.modalEdit = this.modalEdit.bind(this);
    this.edit = this.edit.bind(this);

    this.modalChangePassword = this.modalChangePassword.bind(this);
    this.handlePassword = this.handlePassword.bind(this);
    this.confirmPassword = this.confirmPassword.bind(this);
    this.changePassword = this.changePassword.bind(this);

    this.logout = this.logout.bind(this);
  }

  /* === Handle Token Expired ================================================= */

  handleToken() {
    this.setState(
      {
        isLoading: true,
      },
      () => {
        /* Store Request Body */
        const data_form = new URLSearchParams({
          clientId: process.env.REACT_APP_APP_ID,
          sessionId: localStorage.getItem("local_session"),
        });

        /* Request New Token */
        AuthAPI.relogin(data_form).then((data) => {
          if (data.status === 200) {
            AuthAPI.getToken(data.sso_code).then((result) => {
              localStorage.setItem("token", result.access_token);
              localStorage.setItem("refresh_token", result.refresh_token);
              localStorage.setItem("announcement", "no");
              window.location.reload();
            });
          } else {
            localStorage.clear();
            window.location.reload();
          }
        });
      }
    );
  }

  /* === Fetch User Data ====================================================== */

  fetchNow() {
    this.setState(
      {
        isLoading: true,
      },
      () => {
        /* Request User Data */
        AuthAPI.user().then((data) => {
          if (data.status === 200) {
            /* Request User's Apps Access */
            UserAPI.get(data.data._id).then((apps) => {
              /* Request populated Cluster */
              ClusterAPI.getAll().then((cluster) => {
                /* Request Public Apps */
                ApplicationAPI.get().then((apps2) => {
                  /* Store Variable */
                  let result = [],
                    publicApps = [],
                    populatedCluster = [];

                  /* Store User's Apps */
                  if (apps.status === 200) {
                    result = apps.data.apps;
                  }

                  /* Store Public Apps */
                  if (apps2.status === 200) {
                    for (let value of apps2.data) {
                      if (value.isPublic) {
                        publicApps.push(value);
                      }
                    }
                  }

                  /* filter Cluster exclude non public app  */
                  let temp = {};
                  if (cluster.status === 200) {
                    for (const el of cluster.data) {
                      temp = {};
                      if (el.apps.length > 0) {
                        temp.clusterName = el.name;
                        temp.apps = [];
                        for (const item of el.apps) {
                          if (item.isPublic) {
                            temp.apps.push(item);
                          }
                        }
                        populatedCluster.push(temp);
                      }
                    }
                  }

                  /* Update */
                  this.setState(
                    {
                      isLoading: false,
                      apps: result,
                      user: data.data,
                      publicApps,
                      populatedCluster,
                    },
                    () => {
                      // const validatePoliteknikPUEmail = email => /^[a-zA-Z0-9._%+-]+@politeknikpu\.ac\.id$/.test(email);
                      // if (!validatePoliteknikPUEmail(this.state.user.email)) {
                      //   toast.warning("Email tidak terdeteksi / tidak menggunakan domain Poltek PU", { autoClose: false })
                      //   this.modalEdit();
                      // }
                      /* Check if Announcement Variable Exist */
                      if (localStorage.getItem("announcement")) {
                        /* Check if Already Showed */
                        if (localStorage.getItem("announcement") === "no") {
                          /* Show Then Update Value */
                          // this.state.modal.announcement.show();
                          localStorage.setItem("announcement", "yes");
                        }
                      } else {
                        /* Show Then Update Value */
                        this.state.modal.announcement.show();
                        localStorage.setItem("announcement", "yes");
                      }
                    }
                  );
                });
              });
            });
          } else {
            /* Handle User Not Authorized */
            localStorage.clear();
            window.location.reload();
          }
        });
      }
    );
  }

  /* === Handle User Input ==================================================== */

  handleChange(event) {
    /* Assign and Store Data */
    let target = event.target.getAttribute("data-type");
    let data = this.state[target];

    /* Change Value */
    data[event.target.name] = event.target.value;

    /* Update */
    this.setState({
      [target]: data,
    });
  }

  /* === Change Password ====================================================== */

  modalChangePassword() {
    /* Show Modal */
    this.setState(
      {
        password: ["", ""],
      },
      () => {
        this.state.modal.password.show();
      }
    );
  }

  handlePassword(event) {
    /* Handle Password Input */
    let password = this.state.password;

    if (event.target.name === "password") {
      password[0] = event.target.value;
    } else {
      password[1] = event.target.value;
    }

    this.setState({
      password,
    });
  }

  confirmPassword() {
    /* Check is Password Same or Not */
    let isPass = "";

    if (this.state.password[0] === "" || this.state.password[1] === "") {
      isPass = "";
    } else if (this.state.password[0] === this.state.password[1]) {
      isPass = "pass";
    } else {
      isPass = "no";
    }

    return isPass;
  }

  changePassword(e) {
    /* Prevent Default */
    e.preventDefault();

    this.setState(
      {
        isLoading: true,
      },
      () => {
        /* Request Change Password */
        UserAPI.changePassword({ password: this.state.password[0] }).then(
          (result) => {
            this.setState(
              {
                isLoading: false,
              },
              () => {
                /* Hide Modal */
                this.state.modal.password.hide();

                /* Handle Response */
                if (result.status === 200) {
                  toast.success("Password Berhasil Diubah", {
                    position: toast.POSITION.TOP_CENTER,
                    theme: "colored",
                  });
                } else {
                  toast.error(
                    "Password Gagal Diubah, Silahkan Coba Beberapa Saat Lagi",
                    {
                      position: toast.POSITION.TOP_CENTER,
                      theme: "colored",
                    }
                  );
                }
              }
            );
          }
        );
      }
    );
  }
  /* === Change Profile ======================================================= */

  modalEdit() {
    this.setState(
      {
        modalProfile: {
          name: this.state.user.name,
          email: this.state.user.email,
          isVerified:
            this.state.user.email !== this.state.modalProfile.email
              ? false
              : true,
        },
      },
      () => {
        this.state.modal.profile.show();
      }
    );
  }

  edit(event) {
    event.preventDefault();

    this.setState(
      {
        isLoading: true,
        modalProfile: {
          ...this.state.modalProfile,
          isVerified:
            this.state.user.email !==
              this.state.modalProfile.email.replace(/\s/g, "")
              ? false
              : true,
        },
      },
      () => {
        const validatePoliteknikPUEmail = email => /^[a-zA-Z0-9._%+-]+@politeknikpu\.ac\.id$/.test(this.state.modalProfile.email);
        if (validatePoliteknikPUEmail(this.state.user.email)) {
          UserAPI.put(this.state.user._id, this.state.modalProfile).then(
            (res) => {
              this.setState(
                {
                  isLoading: false,
                },
                () => {
                  /* Hide Modal */
                  this.state.modal.profile.hide();
                  this.fetchNow();
                  /* Handle Response */
                  if (res.status === 200) {
                    toast.success("Data Berhasil Diubah", {
                      position: toast.POSITION.TOP_CENTER,
                      theme: "colored",
                    });
                  } else {
                    toast.error(
                      "Data Gagal Diubah, Silahkan Coba Beberapa Saat Lagi. (Hint: Email/NIP sudah terpakai)",
                      {
                        position: toast.POSITION.TOP_CENTER,
                        theme: "colored",
                      }
                    );
                  }
                }
              );
            }
          );
        } else {
          this.setState({ isLoading: false })
          toast.error("Format email tidak sesuai")
        }
      }
    );
  }

  /* === Logout Protocol ====================================================== */

  logout() {
    this.setState(
      {
        isLoading: true,
      },
      () => {
        /* Store Data Needed */
        const body = new URLSearchParams({
          clientId: process.env.REACT_APP_APP_ID,
          refresh_token: localStorage.getItem("refresh_token"),
        });

        /* Request to Logout */
        AuthAPI.logout(body).then(() => {
          localStorage.clear();
          window.location.reload();
        });
      }
    );
  }

  componentDidMount() {
    if (!localStorage.getItem("token")) {
      this.handleToken();
    } else {
      this.setState(
        {
          modal: {
            password: new Modal(document.getElementById("password-modal")),
            announcement: new Modal(
              document.getElementById("announcement-modal")
            ),
            profile: new Modal(document.getElementById("modal-edit")),
          },
        },
        () => {
          this.fetchNow();
        }
      );
    }
  }

  checkAll() {
    let disabled = false;

    for (let value of Object.keys(this.state.modalProfile)) {
      if (this.state.modalProfile[value] === "") {
        disabled = true;
      }
    }

    return disabled;
  }

  render() {
    if (localStorage.getItem("token")) {
      return (
        <React.Fragment>
          <Helmet>
            <style>{`body { background-color: #ededed; font-family: 'Inter', sans-serif; }`}</style>
          </Helmet>
          <Loading show={this.state.isLoading} />
          <div id="issp-nav">
            <div className="issp-container">
              <nav className="d-flex align-items-center justify-content-between">
                <img src={Brand} alt="" />
                <div className="dropdown">
                  <img
                    className="dropdown-toggle issp-avatar"
                    data-bs-toggle="dropdown"
                    src={AvaIcon}
                    alt="Avatar Icon"
                  />
                  <ul
                    className="dropdown-menu dropdown-menu-end"
                    style={{ fontSize: "14px" }}
                  >
                    <li>
                      <button
                        className="dropdown-item text-dark px-4"
                        type="button"
                        onClick={this.modalEdit}
                      >
                        <User
                          className="me-2"
                          style={{ marginTop: "-2px" }}
                          size={14}
                        />
                        <span>Ubah Profil</span>
                      </button>
                    </li>
                    <li>
                      <button
                        className="dropdown-item text-dark px-4"
                        type="button"
                        onClick={this.modalChangePassword}
                      >
                        <Lock
                          className="me-2"
                          style={{ marginTop: "-2px" }}
                          size={14}
                        />
                        <span>Ubah Password</span>
                      </button>
                    </li>
                    <li>
                      <button
                        className="dropdown-item text-dark px-4"
                        type="button"
                        onClick={this.logout}
                      >
                        <LogOut
                          className="me-2"
                          style={{ marginTop: "-2px" }}
                          size={14}
                        />
                        <span>Keluar</span>
                      </button>
                    </li>
                  </ul>
                </div>
              </nav>
            </div>
          </div>
          <div id="issp-apps">
            <div className="issp-container">
              {this.state.apps.length > 0 && (
                <React.Fragment>
                  <div className="row mb-3">
                    <div className="col-12">
                      <h2 className="issp-title">Aplikasi Anda</h2>
                    </div>
                  </div>
                  <div className="row">
                    {this.state.apps.map((value, index) => {
                      // eslint-disable-next-line array-callback-return
                      if (value?.appId?._id === "000000000000000000000002") return
                      return (
                        <div key={index} className="col-md-4 mb-4">
                          <a
                            target="_blank"
                            rel="noopener noreferrer"
                            className="app-link"
                            href={`${window.location.protocol}//${value.appId.domain}`}
                          >
                            <div className="card app-card">
                              {value.appId.thumbnail &&
                                value.appId.thumbnail !== "-" &&
                                value.appId.thumbnail !== '"-"' ? (
                                <img
                                  className="card-img-top"
                                  src={
                                    process.env.REACT_APP_API +
                                    "/v2/api/files/" +
                                    value.appId.thumbnail
                                  }
                                  alt="Application Screenshot Thumbnail"
                                />
                              ) : (
                                <img
                                  className="card-img-top"
                                  src={NoThumbnail}
                                  alt="Application Screenshot Thumbnail"
                                />
                              )}
                              <div className="card-body">
                                <h2 className="app-title">
                                  {value.appId.name}
                                </h2>
                                <p className="app-description">
                                  {value.appId.description || '-'}
                                </p>
                              </div>
                            </div>
                          </a>
                        </div>
                      );
                    })}
                  </div>
                </React.Fragment>
              )}
              <div className="row my-3">
                <div className="col-12">
                  <h2 className="issp-title">Aplikasi Publik</h2>
                </div>
              </div>
              <div className="row px-3">
                {this.state.populatedCluster.map((el, idx) => {
                  return (
                    <React.Fragment key={idx}>
                      {/* <h2 className="issp-sub-title">{el.clusterName}</h2> */}
                      {el.apps.map((value, index) => {
                        // eslint-disable-next-line array-callback-return
                        if (value._id === "000000000000000000000002") return
                        return (
                          <div key={index} className="col-md-4 mb-4">
                            <a
                              target="_blank"
                              rel="noopener noreferrer"
                              className="app-link"
                              href={`${window.location.protocol}//${value.domain}`}
                            >
                              <div className="card app-card">
                                {value.thumbnail &&
                                  value.thumbnail !== "-" &&
                                  value.thumbnail !== '"-"' ? (
                                  <img
                                    className="card-img-top"
                                    src={
                                      process.env.REACT_APP_API +
                                      "/v2/api/files/" +
                                      value.thumbnail
                                    }
                                    alt="Application Screenshot Thumbnail"
                                  />
                                ) : (
                                  <img
                                    className="card-img-top"
                                    src={NoThumbnail}
                                    alt="Application Screenshot Thumbnail"
                                  />
                                )}
                                <div className="card-body">
                                  <h2 className="app-title">{value.name}</h2>
                                  <p className="app-description">
                                    {value.description || '-'}
                                  </p>
                                  <div className="d-flex align-items-center justify-content-between">
                                    <div className="app-role">Publik</div>
                                  </div>
                                </div>
                              </div>
                            </a>
                          </div>
                        );
                      })}
                    </React.Fragment>
                  );
                })}
              </div>
            </div>
          </div>

          <div
            className="modal fade"
            id="modal-edit"
            tabIndex="-1"
            aria-labelledby="password-modal"
            aria-hidden="true"
          >
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">Ubah Detail Pengguna</h5>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <form onSubmit={this.edit}>
                  <div className="modal-body">
                    <div className="form-group">
                      <label className="text-dark mb-1">Nama</label>
                      <input
                        onChange={this.handleChange}
                        data-type="modalProfile"
                        value={this.state.modalProfile.name}
                        name="name"
                        className="form-control py-3"
                        type="text"
                        placeholder="Masukkan nama lengkap"
                      />
                    </div>
                    <div className="form-group">
                      <label className="text-dark mb-1">Email</label>
                      <input
                        onChange={this.handleChange}
                        data-type="modalProfile"
                        value={this.state.modalProfile.email}
                        name="email"
                        className="form-control py-3"
                        type="email"
                        placeholder="Masukkan email @poltekpu.ac.id"
                      />
                    </div>
                    {/* <div className="form-group">
                      <label className="text-dark mb-1">Telepon</label>
                      <input
                        onChange={this.handleChange}
                        data-type="modalProfile"
                        value={this.state.modalProfile.phone}
                        name="phone"
                        className="form-control py-3"
                        type="tel"
                        placeholder="Masukkan telepon (628xxxx)"
                        pattern="^[0-9]*$"
                        minlength="9"
                        maxlength="15"
                      />
                    </div>
                    <div className="form-group">
                      <label className="text-dark mb-1">NIP</label>
                      <input
                        onChange={this.handleChange}
                        data-type="modalProfile"
                        value={this.state.modalProfile.nip}
                        name="nip"
                        className="form-control py-3"
                        type="tel"
                        placeholder="Masukkan NIP"
                        pattern="^[0-9]*$"
                      />
                    </div> */}
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-secondary"
                      data-bs-dismiss="modal"
                    >
                      Batal
                    </button>
                    <button
                      type="submit"
                      className="btn btn-primary"
                      disabled={this.checkAll()}
                    >
                      Simpan
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>

          <div
            className="modal fade"
            id="password-modal"
            tabIndex="-1"
            aria-labelledby="password-modal"
            aria-hidden="true"
          >
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">Ubah Password</h5>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <form onSubmit={this.changePassword}>
                  <input
                    defaultValue={this.state.user.username}
                    type="text"
                    name="username"
                    className="d-none"
                    autoComplete="username"
                  />
                  <div className="modal-body">
                    <div className="mb-3">
                      <label className="form-label">Password Baru</label>
                      <input
                        value={this.state.password[0]}
                        onChange={this.handlePassword}
                        type="password"
                        name="password"
                        className={
                          "form-control" +
                          (this.confirmPassword() === "no" ? " is-invalid" : "")
                        }
                        placeholder="Masukkan password"
                        autoComplete="new-password"
                        minlength="8"
                      />
                    </div>
                    <div className="mb-3">
                      <label className="form-label">Konfirmasi Password</label>
                      <input
                        value={this.state.password[1]}
                        onChange={this.handlePassword}
                        type="password"
                        name="password_confirm"
                        className={
                          "form-control" +
                          (this.confirmPassword() === "no" ? " is-invalid" : "")
                        }
                        placeholder="Masukkan kembali password"
                        autoComplete="new-password"
                      />
                      <div className="invalid-feedback">
                        Password belum sesuai
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-secondary"
                      data-bs-dismiss="modal"
                    >
                      Batal
                    </button>
                    <button
                      disabled={
                        this.confirmPassword() === "pass" ? false : true
                      }
                      type="submit"
                      className="btn btn-primary"
                    >
                      Simpan
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>

          <div
            className="modal fade"
            id="announcement-modal"
            tabIndex="-1"
            aria-labelledby="password-modal"
            aria-hidden="true"
          >
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">Pengumuman</h5>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div className="modal-body modal-issp">
                  <div
                    id="announcement-carousel"
                    className="carousel slide"
                    data-bs-ride="carousel"
                  >
                    <div className="carousel-indicators">
                      <button
                        type="button"
                        data-bs-target="#announcement-carousel"
                        data-bs-slide-to="0"
                        className="active"
                        aria-current="true"
                        aria-label="Slide 1"
                      ></button>
                      <button
                        type="button"
                        data-bs-target="#announcement-carousel"
                        data-bs-slide-to="1"
                        aria-label="Slide 2"
                      ></button>
                    </div>
                    <div className="carousel-inner">
                      <div className="carousel-item active">
                        <img
                          src="https://citylab.itb.ac.id/pikkc/wp-content/uploads/2020/10/gscm-brief.png"
                          alt="Banner 2"
                        />
                      </div>
                      <div className="carousel-item">
                        <img
                          src="https://citylab.itb.ac.id/pikkc/wp-content/uploads/2020/11/Capture.png"
                          alt="Banner 1"
                        />
                      </div>
                    </div>
                    <button
                      className="carousel-control-prev"
                      type="button"
                      data-bs-target="#announcement-carousel"
                      data-bs-slide="prev"
                    >
                      <span
                        className="carousel-control-prev-icon"
                        aria-hidden="true"
                      ></span>
                      <span className="visually-hidden">Previous</span>
                    </button>
                    <button
                      className="carousel-control-next"
                      type="button"
                      data-bs-target="#announcement-carousel"
                      data-bs-slide="next"
                    >
                      <span
                        className="carousel-control-next-icon"
                        aria-hidden="true"
                      ></span>
                      <span className="visually-hidden">Next</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>
      );
    } else {
      return null;
    }
  }
}

export default Home;
